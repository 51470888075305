/*****************************************************************************************************
 *
 * Undock Web App Angular Client Development Environment
 * Production Environment Configuration
 *
 *
 * This environment will overwrite the one in the default environment.ts during a production build
 * e.g. $ ng build --prod
 * or $ ng serve -c production
 * or $ ng serve-prod
 *
 */
import { AppMode } from '@undock/core'
import { RxJSDebugLevel } from '@undock/core/contracts/rxjs-debug-level'

export const environment = {
    appMode: 'prod' as AppMode,

    enableSidebar: true,

    enableErrorTracking: true,
    logRocketId: 'oozzgi/undock',

    // App Config
    dockLink: 'http://dock.link/mi',
    proposalLink: 'https://undock.to/',
    clientDomain: 'https://undock.com/',
    embedDomain: 'https://app.undock.com/',
    apiURL: 'https://undock-193015.appspot.com/',
    apiPlatformURL: 'https://api.undock.com/',
    requestFeatureURL: 'https://matter.undock.com/feature-requests',
    debugMode: false,
    rxjsDebugLevel: RxJSDebugLevel.OFF,
    enableTracking: true,
    prodMode: true,
    production: true,
    forceHttpsOnDev: true,
    useExperimentalFeatures: true,
    useExperimentalPeoplePage: true,
    appVersion: 'ud00063',
    actualCalendarVersion: 3,

    // Analytics
    enableAnalytics: true,
    enableHeapAnalytics: true,
    enableGoogleAnalytics: true,
    enableGoogleTagManager: true,
    enableSegmentAnalytics: true,
    enableCannySDK: true,
    googleAnalyticsTrackingId: 'UA-250497264',
    googleTagManagerTrackingId: 'UA-160161596-3',
    segmentAnalyticsTrackingId: 'bV5Zcjcm7PeZAFwVMLa4NggTa7wtWh3P',
    cannyAppId: '6071a6334c231e2b2f3ac332',
    gistTourId: 283,
    gistStepId: '283-step-c7e54ca8-1681415317897',

    // Integrations
    googleApiUrl: 'https://apis.google.com/js/api.js',
    googleApiClientUrl: 'https://accounts.google.com/gsi/client',
    googleClientId: '689053353097-tpf84rmqj0a96dhhjt95dubdi8ppeapu.apps.googleusercontent.com',
    googleContactsScope: 'https://www.googleapis.com/auth/contacts.readonly',
    googleDirectoryScope: 'https://www.googleapis.com/auth/directory.readonly',
    googleApiKey: 'AIzaSyCJ3SGEv_2fYB7vu7MIm5S2499eIyI5w6o',
    microsoftClientId: '0c78673b-03cf-4162-82b4-36006964084b',
    zoomCallbackPath: 'zoom-auth',
    stripeApiKey: 'pk_live_UveFRFuYzOziBrtq4HXC3NGV',

    gistAppId: 'qkktikyx',
    usetifulToken: null,
    crispWebsiteId: null,
        
    firebase: {
        apiKey: 'AIzaSyAKyTiXqP8yospCSZtqR3XwsZfmkLO1YXk',
        authDomain: 'auth.app.undock.com',// experimental domain
        // authDomain: 'undock-platform.firebaseapp.com',
        databaseURL: 'https://undock-platform.firebaseio.com',
        projectId: 'undock-platform',
        storageBucket: 'undock-platform.appspot.com',
        messagingSenderId: '211620569159',
        appId: '1:211620569159:web:98f276f9cbeb7879919162',
        measurementId: 'G-97GK90JR7T',
    },

    chromeExtURL: 'https://chrome.google.com/webstore/detail/undock-meetings/bmppbndbfpegajdcdngndbfpifpbeckd',
    chromeExtensionId: 'bmppbndbfpegajdcdngndbfpifpbeckd',
    schedulingWindowAllowedDomains: [
        'https://mail.google.com',
        'https://outlook.live.com',
        'https://outlook.office365.com',
        'https://outlook.office.com',
        'https://mail.superhuman.com',
        'http://localhost:4200/install',
        'http://localhost:4200/signup',
        'http://localhost:4201/install',
        'http://localhost:4201/signup',
        'https://dev.undock.com/install',
        'https://dev.undock.com/signup',
        'https://dev5.undock.com/install',
        'https://dev5.undock.com/signup',
        'https://next.undock.com/install',
        'https://next.undock.com/signup',
        'https://app.undock.com/install',
        'https://app.undock.com/signup',
        'https://io.undock.com/install',
        'https://io.undock.com/signup'
    ],
    calendarPromptAllowedDomains: [
        'https://calendar.google.com',
        'https://outlook.live.com/calendar',
        'https://outlook.office365.com/calendar',
        'https://outlook.office.com/calendar',
    ]
}
